import { RaRecord, useRecordContext } from "ra-core";
import * as React from "react";
import { ButtonProps } from "react-admin";
import { Lock, LockOpen } from "@mui/icons-material";
import { Badge, BadgeProps, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "../../components/LoadingButton";
import { UserApi } from "../../api/UserApi";
import { useConfirm } from "../../hook/ConfirmContext";

type ClickCallback = <
  RecordType extends RaRecord | Omit<RaRecord, "id"> = RaRecord
>(
  record: RecordType,
  resource: string
) => Promise<any>;

interface Props {
  resource?: string;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

export type UnLockButtonProps = Props & ButtonProps;

export const UnLockButton = (props: UnLockButtonProps) => {
  const { onClick, ...rest } = props;
  const record = useRecordContext(props);
  const isLock = record?.status === "LOCK";
  const confirm = useConfirm();

  const handleClick = async () => {
    const isConfirmed = await confirm({
      title: "是否确定?",
      description: isLock ? "解锁" : "锁定",
    });
    if (!isConfirmed) {
      return;
    }
    if (record?.status === "LOCK") {
      await UserApi.unlock(record?.id as string);
    } else {
      await UserApi.lock(record?.id as string);
    }
  };

  if (!record) return null;

  return (
    <LoadingButton
      label={isLock ? "解锁" : "正常"}
      doLoading={handleClick}
      {...(rest as any)}
      icon={(f, loading) => {
        if (loading) {
          return <CircularProgress size={18} color="info" />;
        } else {
          if (isLock) {
            return <Lock fontSize={"small"} />;
          } else {
            return <LockOpen fontSize={"small"} />;
          }
        }
      }}
    >
      {isLock ? "解锁" : "正常"}
    </LoadingButton>
  );
};
