import { Delete } from "@mui/icons-material";
import { CommandProps } from "./Command";
import React from "react";
import { BaseCommand } from "./BaseCommand";
import {
  RedirectionSideEffect,
  useDeleteWithUndoController,
  useRecordContext,
  useResourceContext,
} from "ra-core";

export interface DeleteCommandProps extends Partial<CommandProps> {
  redirect?: RedirectionSideEffect;
}

export function DeleteCommand(props: DeleteCommandProps) {
  const {
    command,
    enableTool = false,
    enableRow = true,
    permission = "delete",
    redirect = "list",
    ...rest
  } = props;
  const record = useRecordContext(props);
  const resource = useResourceContext();
  const { handleDelete } = useDeleteWithUndoController({
    record,
    resource,
    redirect: redirect,
  });
  return (
    <BaseCommand
      variant={"contained"}
      confirmTitle={"确认!"}
      confirmContent={`是否确定删除:${record?.id}?`}
      command={{
        onHandle: async (e) => {
          handleDelete(e);
        },
        confirm: true,
        label: "删除",
        icon: <Delete />,
        ...command,
        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      enableTool={enableTool}
      enableRow={enableRow}
      permission={permission}
      {...rest}
    />
  );
}
