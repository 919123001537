import * as React from "react";
import { ReactElement } from "react";
import PropTypes from "prop-types";
import {
  ChoicesContextProvider,
  InputProps,
  ListContextProvider,
  ResourceContextProvider,
  useChoicesContext,
  useListController,
  UseReferenceArrayInputParams,
} from "ra-core";
import { Box, InputLabel } from "@mui/material";
import { Pagination, SearchInput } from "react-admin";

const defaultSort = { field: "id", order: "DESC" as const };

export const MyReferenceSearchTableInput = (props: MySearchRefTableProps) => {
  const { children, filter, label, reference, sort = defaultSort } = props;

  if (React.Children.count(children) !== 1) {
    throw new Error(
      "<MySearchRefTable> only accepts a single child (like <Datagrid>)"
    );
  }

  const controllerProps = useChoicesContext({
    ...props,
    filter: { sb: 1 },
    sort,
  });

  return (
    // @ts-ignore
    <ResourceContextProvider value={reference}>
      <ChoicesContextProvider value={controllerProps}>
        <Box p={"0px 0"}>
          {label && <InputLabel sx={{ m: "0 0 0px 0" }}>{label}:</InputLabel>}
          <Box display={"grid"}>
            <ChildList {...props}>{children}</ChildList>
          </Box>
        </Box>
      </ChoicesContextProvider>
    </ResourceContextProvider>
  );
};

function ChildList(props: MySearchRefTableProps) {
  const { children, debounce, perPage, reference, sort = defaultSort } = props;

  const [filter, setFilter] = React.useState(props.filter);

  const listContext = useListController({
    debounce,
    filter,
    sort,
    resource: reference,
    perPage,
  });

  const postFilters = [
    <SearchInput
      key="search"
      source="keyword"
      helperText={false}
      alwaysOn
      onChange={(e) => {
        if (e && e.target && e.target.value) {
          setFilter({ ...filter, keyword: e.target.value });
        } else {
          setFilter({ ...filter, keyword: "" });
        }
      }}
    />,
  ];
  return (
    <ListContextProvider value={listContext}>
      <Box>{postFilters}</Box>
      <Box>{children}</Box>
      <Box>
        <Pagination />
      </Box>
    </ListContextProvider>
  );
}

MyReferenceSearchTableInput.propTypes = {
  children: PropTypes.element,
  filter: PropTypes.object,
  label: PropTypes.string,
  page: PropTypes.number,
  perPage: PropTypes.number,
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.oneOf(["ASC", "DESC"]),
  }),
  source: PropTypes.string,
};

const defaultFilter = {};

export interface MySearchRefTableProps
  extends InputProps,
    UseReferenceArrayInputParams {
  children?: ReactElement;
  label?: string;

  [key: string]: any;
}
