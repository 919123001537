import type { ChangeEvent } from "react";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { LinearProgress } from "@mui/material";
import { Result } from "../utils/request";
import { useUpload } from "../hook/useUpload";
import { useNotify } from "react-admin";
import { RiFileFill } from "@remixicon/react";

export interface FileUploadProps {
  id: string;
  onSuccess?: (result?: string[]) => void;
  path?: string;
  defaultImage?: string;
}

export function FileUpload(props: FileUploadProps) {
  const { id, onSuccess, path, defaultImage } = props;
  const [previewImage, setPreviewImage] = useState<string | null>(
    defaultImage || null
  );
  const [cancelUpload, setCancelUpload] = useState<(() => void) | null>(null);
  const notify = useNotify();
  useEffect(() => {
    return () => {
      // 在组件卸载时取消请求
      cancelUpload?.();
    };
  }, [cancelUpload]);
  const handleError = (error: Error) => {
    notify(error.message, { autoHideDuration: 5000, type: "error" });
    setPreviewImage(null);
  };

  function getOnSuccess(result: Result<string[] | undefined>) {
    onSuccess?.(result?.data);
  }

  const { progress, uploadFile } = useUpload<string[]>({
    onError: handleError,
    onSuccess: (result: Result<string[] | undefined>) => getOnSuccess(result),
  });

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) {
      setPreviewImage(null);
      return;
    }

    setPreviewImage(file.name);

    //path
    const cancel = await uploadFile(file, "/api/appFile/upload");
    setCancelUpload(() => cancel);
  };

  return (
    <Box>
      <input
        accept="application/vnd.android.package-archive" // 限制文件类型为 PNG
        style={{ display: "none" }}
        id={id}
        type="file"
        onChange={handleFileUpload}
      />
      <label htmlFor={id}>
        <Button component="span" variant="outlined" startIcon={<RiFileFill />}>
          上传
        </Button>
      </label>
      <Box margin={"8px 0"}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      {previewImage && (
        <Card variant="outlined" sx={{ padding: "8px", margin: "8px 0" }}>
          {previewImage}
        </Card>
      )}
    </Box>
  );
}
