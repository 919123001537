import { Add } from "@mui/icons-material";
import { CommandProps } from "./Command";
import React from "react";
import { BaseCommand } from "./BaseCommand";
import { useCreatePath, useResourceContext } from "ra-core";

export interface CreateCommandProps extends Partial<CommandProps> {}

export function CreateCommand(props: CreateCommandProps) {
  const {
    command,
    enableTool = true,
    enableRow = false,
    permission = "create",
    ...rest
  } = props;
  const createPath = useCreatePath();
  const resource = useResourceContext();
  return (
    <BaseCommand
      command={{
        to: createPath({ resource, type: "create" }),
        label: "新建",
        icon: <Add />,
        // to: (record, rd) => `#/${rd?.name}/create`,
        ...command,
      }}
      enableTool={enableTool}
      enableRow={enableRow}
      permission={permission}
      {...rest}
    />
  );
}
