import { RaRecord, useRecordContext } from "ra-core";
import * as React from "react";
import { ButtonProps } from "react-admin";
import { Lock, LockOpen } from "@mui/icons-material";
import { Badge, BadgeProps, CircularProgress } from "@mui/material";
import { LoadingButton } from "./LoadingButton";
import { styled } from "@mui/material/styles";

type ClickCallback = <
  RecordType extends RaRecord | Omit<RaRecord, "id"> = RaRecord
>(
  record: RecordType,
  resource: string
) => Promise<any>;

interface Props {
  resource?: string;
  onClick: ClickCallback;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

export type UnLockButtonProps = Props & ButtonProps;

export const UnLockButton = (props: UnLockButtonProps) => {
  const { onClick, ...rest } = props;
  const record = useRecordContext(props)!;
  const isLock = record.lock >= 6;

  const handleClick = async (record: any, resource: string) => {
    await onClick(record, resource);
  };

  if (!record) return null;

  return (
    <StyledBadge badgeContent={record.lock} invisible={!isLock} color="info">
      <LoadingButton
        label={isLock ? "解锁" : "正常"}
        disabled={!isLock}
        doLoading={handleClick}
        {...(rest as any)}
        icon={(f, loading) => {
          if (loading) {
            return <CircularProgress size={18} color="info" />;
          } else {
            if (isLock) {
              return <Lock fontSize={"small"} />;
            } else {
              return <LockOpen fontSize={"small"} />;
            }
          }
        }}
      />
    </StyledBadge>
  );
};
