import { CommandProps } from "../../components/command/Command";
import { BaseCommand } from "../../components/command/BaseCommand";
import { useRecordContext } from "ra-core";
import React from "react";
import get from "lodash/get";
import { useConfirmForm } from "../../hook/ConfirmContext";
import { GroupApi } from "../../api/GroupApi";
import { RiChat2Line, RiChatOffFill } from "@remixicon/react";

export interface MuteCommandProps extends Partial<CommandProps> {}

export function MuteCommand(props: MuteCommandProps) {
  const {
    command,
    enableTool = false,
    enableRow = true,
    permission = "update",
    ...rest
  } = props;
  const record = useRecordContext(props);
  const id = get(record, "id") as string;
  const confirm = useConfirmForm();
  return (
    <BaseCommand
      command={{
        isEnable: (rowData: any) => {
          return rowData.status !== 2;
        },
        label: (r) => {
          if (r.status === 3) {
            return "解禁";
          }
          return "禁言";
        },
        confirm: true,
        icon: (r) => {
          if (r?.status === 3) {
            return <RiChat2Line size={18} />;
          }
          return <RiChatOffFill size={18} />;
        },
        onHandle: async (e, r) => {
          if (r.status === 3) {
            await GroupApi.unmute(r.id);
          } else {
            await GroupApi.mute(r.id);
          }
          return true;
        },
        ...command,
        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      enableTool={enableTool}
      enableRow={enableRow}
      permission={permission}
      {...rest}
    />
  );
}
