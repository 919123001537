import VisitorIcon from "@mui/icons-material/People";
import * as React from "react";
import { MyField } from "../../components/types";
import omit from "lodash/omit";
import { Field } from "../../utils/Field";
import { MyList } from "../../components/core/MyList";
import GroupCreate from "./GroupCreate";
import GroupEdit from "./GroupEdit";
import { ResourceType } from "../../components/core/MyResource";
import { MuteMemberCommand } from "./MuteMemberCommand";
import { GroupShow } from "./GroupShow";
import { MyRefTable } from "../../components/core/MyRefTable";
import { KickUserCommand } from "./KickUserCommand";
import dayjs from "dayjs";
import { UserLabel } from "../../utils/userUtils";

const fields: MyField<any>[] = [
  Field.id({
    label: "ID",
    minWidth: 134,
    isList: false,
    isFilter: false,
    isUpdate: false,
    isCreate: false,
    isEdit: false,
  }),
  Field.ref({
    label: "用户",
    source: "userID",
    reference: "users",
    sortable: false,
    isFilter: false,

    minWidth: 150,
    translateLabel: UserLabel,
  }),
  Field.text({
    label: "昵称",
    source: "nickname",
    sortable: false,
    isFilter: false,
  }),
  Field.text({
    label: "邀请用户id",
    source: "inviterUserID",
    sortable: false,
    isFilter: false,
  }),
  Field.text({
    label: "批准用户id",
    source: "operatorUserID",
    sortable: false,
    isFilter: false,
  }),
  Field.functionField({
    label: "来源",
    source: "joinSource",
    sortable: false,
    isFilter: false,
    render(record: any, source: string | undefined): any {
      //1：管理员邀请、2：群成员邀请、3：搜索加入、4：扫码加入
      switch (record.joinSource) {
        case 1:
          return "管理员邀请";
        case 2:
          return "群成员邀请";
        case 3:
          return "搜索加入";
        case 4:
          return "扫码加入";
        default:
          return "未知来源";
      }
    },
  }),
  Field.img({
    maxWidth: 80,
    label: "头像",
    source: "faceURL",
    isEdit: false,
    isCreate: false,
    isUpdate: false,
  }),
  Field.functionField({
    label: "等级",
    source: "roleLevel",
    sortable: false,
    isFilter: false,
    render(record: any, source: string | undefined): any {
      //群成员等级	100：群主、60：管理员、20：普通成员
      switch (record.roleLevel) {
        case 100:
          return "群主";
        case 60:
          return "管理员";
        case 20:
          return "普通成员";
        default:
          return "未知等级";
      }
    },
  }),
  Field.functionField({
    label: "封禁结束时间",
    source: "muteEndTime",
    render(record: any, source: string | undefined): any {
      if (record.muteEndTime) {
        return dayjs(record.muteEndTime).format("YYYY-MM-DD HH:mm:ss");
      }
      return "-";
    },
  }),
  Field.dateTime({
    label: "进入时间",
    source: "joinTime",
  }),
];

let transform = (data: any) => {
  if (data?.nickName) {
    return {
      ...omit(data, ["nickName"]),
      groupOwnerID: data?.nickName,
    };
  }
  return data;
};

export const GroupMemberTable = () => {
  return (
    <MyRefTable
      fields={fields}
      commands={[<MuteMemberCommand />, <KickUserCommand />]}
    />
  );
};

const resource: ResourceType = {
  commands: [],
  show: () => {
    return <GroupShow fields={fields} />;
  },
  list: () => {
    return <MyList fields={fields} />;
  },
  create: () => {
    return <GroupCreate />;
  },
  edit: () => {
    return <GroupEdit />;
  },
  icon: <VisitorIcon />,
};

export default resource;
