import * as React from "react";
import {
  Edit,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from "react-admin";
import { Box, Typography } from "@mui/material";

// 抽象出来的 optionText 函数
const renderUserOptionText = (record: any) =>
  `${record.viewId} (${record.nickname})`;

const GroupEdit = () => (
  <Edit>
    <SimpleForm sx={{ maxWidth: 800 }}>
      {/* 群信息部分 */}
      <SectionTitle label="基本信息" />
      <TextInput source="groupName" label="群名称" validate={[required()]} />
      <TextInput
        source="notification"
        label="群公告"
        validate={[required()]}
        fullWidth
      />
      <TextInput
        source="introduction"
        label="群介绍"
        validate={[required()]}
        fullWidth
      />
      {/* 群设置部分 */}
      <SectionTitle label="群设置" />
      {/* SelectInput for needVerification */}
      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="needVerification"
            label="进群是否需要验证"
            validate={[required()]}
            fullWidth
            choices={[
              { id: 0, name: "申请加入群需要同意，成员邀请可直接进群" },
              { id: 1, name: "所有人进群需要验证，除了群主管理员邀请进群" },
              { id: 2, name: "直接进群" },
            ]}
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          {/* SelectInput for lookMemberInfo */}
          <SelectInput
            source="lookMemberInfo"
            label="是否能查看其他群成员信息"
            validate={[required()]}
            fullWidth
            choices={[
              { id: 0, name: "允许查看群成员信息" },
              { id: 1, name: "不允许查看群成员信息" },
            ]}
          />
        </Box>
      </Box>

      {/* SelectInput for applyMemberFriend */}
      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="applyMemberFriend"
            label="群成员是否能添加好友"
            validate={[required()]}
            fullWidth
            choices={[
              { id: 0, name: "允许从群成员处添加好友" },
              { id: 1, name: "不允许添加" },
            ]}
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}></Box>
      </Box>
    </SimpleForm>
  </Edit>
);

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

const Separator = () => <Box pt="1em" />;

export default GroupEdit;
