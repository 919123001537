import { useResourceContext } from "ra-core";
import { AccountStorage } from "../AccountStorage";
import { isEmpty } from "lodash";

export function usePermission() {
  const resource = useResourceContext()!;

  return function checkPermission(permission?: string) {
    if (isEmpty(permission)) {
      return AccountStorage.hasModule(resource);
    }
    return AccountStorage.hasModule(resource + "." + permission);
  };
}
