import { DeleteButton, EditButton } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { Notifications } from "@mui/icons-material";
import { UserLabel } from "../../utils/userUtils";

const fields: MyField[] = [
  Field.text({
    label: "ID",
    source: "id",
    isCreate: false,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.refArr({
    label: "接受用户",
    source: "userIds",
    reference: "users",
    referenceLabel: "name",
    originSource: "id",
    optionValue: "id",
    fullWidth: true,
    optionText: (r) => `${r.name} - UID:${r.uid}`,
    isFilter: false,
    isList: false,
    isUpdate: false,
    isEdit: false,
  }),
  Field.ref({
    label: "接受用户",
    source: "userId",
    reference: "users",
    referenceLabel: "name",
    originSource: "userId",
    fullWidth: true,
    isFilter: true,
    isList: true,
    isCreate: false,
    isUpdate: false,
    isEdit: true,
    minWidth: 150,
    translateLabel: UserLabel,
  }),
  Field.localesValue({
    label: "标题",
    source: "title",
    isFilter: false,
    isRequired: true,
  }),
  Field.richText({
    label: "内容",
    source: "value",
    fullWidth: true,
    isFilter: false,
    isRequired: true,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList fields={fields} rowActions={[<EditButton />, <DeleteButton />]} />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <Notifications />,
};

export default resource;
