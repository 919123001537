import React, { MouseEvent, ReactElement, useMemo, useState } from "react";
import {
  RaRecord,
  useNotify,
  useRecordContext,
  useRefresh,
  useResourceContext,
} from "ra-core";
import isFunction from "lodash/isFunction";
import { Button, ButtonProps, CircularProgress, SxProps } from "@mui/material";
import { Link, To } from "react-router-dom";

export type IconCallback = <
  RecordType extends RaRecord | Omit<RaRecord, "id"> = RaRecord
>(
  record: RecordType | undefined,
  loading: boolean
) => ReactElement;

type LoadingCallback = <
  RecordType extends RaRecord | Omit<RaRecord, "id"> = RaRecord
>(
  event: React.MouseEvent,
  record: RecordType,
  resource: string
) => Promise<void>;

interface Props {
  resource?: string;
  label?: React.ReactNode;
  to?: To;
  doLoading: LoadingCallback;
  icon?: IconCallback | ReactElement;
}

export type LoadingButtonProps = Props & ButtonProps;

// 提取的默认样式
const defaultButtonStyles: SxProps = {
  p: "2px 4px",
  fontSize: "13px !important",
  "& .MuiSvgIcon-root": { fontSize: "16px !important" },
};

export const LoadingButton = (props: LoadingButtonProps) => {
  const { doLoading, children, disabled, to, icon, sx, ...rest } = props;
  const resource = useResourceContext(props)!;
  const record = useRecordContext(props)!;
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const handleClick = async (e: MouseEvent) => {
    e.stopPropagation();
    setLoading(true);
    try {
      await doLoading(e, record, resource);
    } catch (error) {
      // @ts-ignore
      notify(error?.message || error + "", { type: "error" });
    } finally {
      refresh();
      setLoading(false);
    }
  };

  const curIcon = useMemo(() => {
    if (isFunction(icon)) {
      return icon(record, loading);
    } else {
      return loading ? <CircularProgress size={18} color="info" /> : icon;
    }
  }, [icon, loading, record]);

  if (to)
    return (
      <Button
        sx={[
          defaultButtonStyles,
          // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        size={"small"}
        component={Link}
        to={to}
        onClick={handleClick}
        disabled={disabled || loading}
        startIcon={curIcon}
        {...rest}
      >
        {children}
      </Button>
    );
  else {
    return (
      <Button
        sx={[
          defaultButtonStyles,
          // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        size={"small"}
        onClick={handleClick}
        disabled={disabled || loading}
        startIcon={curIcon}
        {...rest}
      >
        {children}
      </Button>
    );
  }
};
