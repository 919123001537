import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";

export const UserApi = {
  handDeduct: async (userId: string, amount: number): Promise<any> => {
    const { data } = await fetchJson(`${API_URL}/user/amount/handDeduct`, {
      method: "POST",
      body: new URLSearchParams({
        userId,
        amount: amount.toString(),
      }),
    });
    return data;
  },
  handPay: async (userId: string, amount: number): Promise<any> => {
    const { data } = await fetchJson(`${API_URL}/user/amount/handPay`, {
      method: "POST",
      body: new URLSearchParams({
        userId,
        amount: amount.toString(),
      }),
    });
    return data;
  },
  lock: async (userId: string): Promise<any> => {
    const { data } = await fetchJson(`${API_URL}/users/lock/${userId}`, {
      method: "POST",
    });
    return data;
  },
  unlock: async (userId: string): Promise<any> => {
    const { data } = await fetchJson(`${API_URL}/users/unlock/${userId}`, {
      method: "POST",
    });
    return data;
  },
};
