import { CommandProps } from "../../components/command/Command";
import { BaseCommand } from "../../components/command/BaseCommand";
import { useRecordContext } from "ra-core";
import { ContactEmergency } from "@mui/icons-material";
import React from "react";
import get from "lodash/get";
import { useConfirmForm } from "../../hook/ConfirmContext";
import { Pagination, RecordContextProvider } from "react-admin";
import { UserFriendSearchTable } from "../userFriendSearch";
import { MyReferenceSearchTableInput } from "../../components/MyReferenceSearchTableInput";
import { FriendApi } from "../../api/FriendApi";

export interface AddFriendsCommandProps extends Partial<CommandProps> {}

export function AddFriendsCommand(props: AddFriendsCommandProps) {
  const {
    command,
    enableTool = false,
    enableRow = true,
    permission = "update",
    ...rest
  } = props;
  const record = useRecordContext(props);
  const id = get(record, "id") as string;
  const confirm = useConfirmForm();
  return (
    <BaseCommand
      command={{
        isEnable: (rowData: any) => {
          return true;
        },
        label: (r) => {
          return "添加好友";
        },
        icon: <ContactEmergency />,
        onHandle: async () => {
          const form = await confirm({
            title: "添加好友",
            form: (
              <>
                <RecordContextProvider value={{ ...record, friendUserId: [] }}>
                  <MyReferenceSearchTableInput
                    source={"friendUserId"}
                    reference="userFriendSearch"
                    filter={{ userId: id }}
                    pagination={<Pagination />}
                  >
                    <UserFriendSearchTable />
                  </MyReferenceSearchTableInput>
                </RecordContextProvider>
              </>
            ),
          });
          //
          if (form) {
            await FriendApi.importFriend(id, form.friendUserId);
          }
          return true;
        },
        ...command,
        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      enableTool={enableTool}
      enableRow={enableRow}
      permission={permission}
      {...rest}
    />
  );
}
