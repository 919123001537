import * as React from "react";
import { useMemo } from "react";
import { GridActionsCellItemProps } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import { IconButton, Menu, Theme, useMediaQuery } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { isEmpty } from "lodash";
import { CommandProps } from "./Command";
import { RecordContextProvider } from "react-admin";
import { CommandItemContextProvider } from "../../hook/CommandItemContext";

type Item = React.ReactElement<GridActionsCellItemProps>;

interface MyProCommandsProps {
  row: any;
  showIcon?: boolean;
  isInMenu?: boolean;
  commands: React.ReactElement<CommandProps>[];
}

const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];

export function ListRowCommands(props: MyProCommandsProps) {
  const { row, showIcon = false, isInMenu = true } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [menuItems, buttons] = useMemo(() => {
    const menuItems: (React.ReactElement | undefined)[] = [];
    const buttons: (React.ReactElement | undefined)[] = [];

    let index = 0;
    props.commands.forEach((item, i) => {
      if (isXSmall || index <= 2 || !isInMenu) {
        buttons.push(
          React.cloneElement(item, {
            key: `${i}`,
            isMenu: false,
            showIcon: showIcon,
          })
        );
      } else {
        menuItems.push(
          React.cloneElement(item, {
            key: `${i}`,
            isMenu: true,
            onClickEnd: handleClose,
          })
        );
      }
      index++;
    });

    return [menuItems, buttons];
  }, [props.commands, isXSmall]);

  return (
    <>
      <CommandItemContextProvider isInRow={true} isInTool={false}>
        <RecordContextProvider<any> value={row}>
          {buttons}
          {!isEmpty(menuItems) && (
            <IconButton sx={{ p: "0" }} size={"small"} onClick={handleClick}>
              <MoreVertIcon fontSize={"small"} />
            </IconButton>
          )}
          {!isEmpty(menuItems) && (
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {menuItems}
            </Menu>
          )}
        </RecordContextProvider>
      </CommandItemContextProvider>
    </>
  );
}
