import { RemoveRedEye } from "@mui/icons-material";
import { CommandProps } from "./Command";
import React from "react";
import { BaseCommand } from "./BaseCommand";
import { useCreatePath, useRecordContext, useResourceContext } from "ra-core";

export interface ShowCommandProps extends Partial<CommandProps> {}

export function ShowCommand(props: ShowCommandProps) {
  const {
    command,
    enableTool = false,
    enableRow = true,
    permission = "show",
    ...rest
  } = props;
  const createPath = useCreatePath();
  const record = useRecordContext(props);
  const resource = useResourceContext();
  const to = createPath({ type: "show", resource, id: record?.id });
  return (
    <BaseCommand
      command={{
        to: to,
        label: "查看",
        icon: <RemoveRedEye />,
        // to: (record, rd) => `#/${rd?.name}/create`,
        ...command,
      }}
      enableTool={enableTool}
      enableRow={enableRow}
      permission={permission}
      {...rest}
    />
  );
}
