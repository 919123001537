import { OverridableStringUnion } from "@mui/types";
import { ButtonPropsColorOverrides } from "@mui/material/Button/Button";
import * as React from "react";
import { ReactElement } from "react";
import { IconCallback } from "../components/LoadingButton";
import { ResourceDefinition } from "ra-core";

type LabelCallback = (rowData?: any) => React.ReactNode;

export interface Command {
  label?: LabelCallback | React.ReactNode;
  icon?: IconCallback | ReactElement;
  onHandle?: (e: React.MouseEvent, rowData?: any) => Promise<any>;
  multiple?: boolean;
  to?:
    | ((
        row?: any,
        resourceDefinition?: ResourceDefinition
      ) => string | undefined)
    | string; //是否显示在行内
  isEnable?: (rowData: any) => boolean;
  children?: (rowData?: any) => React.ReactElement | undefined;
  confirm?: boolean;
  color?: OverridableStringUnion<
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning",
    ButtonPropsColorOverrides
  >;
}

export const defaultCommandProps: Partial<Command> = {
  confirm: true,
  multiple: false,
  color: "primary",
};
