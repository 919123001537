import React from "react";
import { Pagination, ReferenceManyField, TabbedShowLayout } from "react-admin";
import { MyShow } from "../../components/core/MyShow";
import { MyShowFields } from "../../components/MyShowFields";
import { MyField } from "../../components/types";
import { GroupRedRecordTable } from "../groupRedRecord";

export function GroupRedShow({ fields }: { fields: MyField<any>[] }) {
  return (
    <MyShow>
      <TabbedShowLayout value={0}>
        <TabbedShowLayout.Tab label="群详细信息">
          <MyShowFields fields={fields} />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="领取记录" path="groupMembers">
          <ReferenceManyField
            reference="groupRedRecords"
            target="groupRedId"
            source={"id"}
            label={false}
            pagination={<Pagination />}
          >
            <GroupRedRecordTable />
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </MyShow>
  );
}
