import VisitorIcon from "@mui/icons-material/People";
import { MyList } from "../../components/core/MyList";
import { maxLength, minLength, regex, TextInput } from "react-admin";
import * as React from "react";
import { MyField } from "../../components/types";
import omit from "lodash/omit";
import { Field } from "../../utils/Field";
import { ResourceType } from "../../components/core/MyResource";
import { ShowCommand } from "../../components/command/ShowCommand";
import { CreateCommand } from "../../components/command/CreateCommand";
import { EditCommand } from "../../components/command/EditCommand";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { keyBy } from "lodash";
import { RiAndroidFill, RiAppleFill, RiGlobalLine } from "@remixicon/react";
import Box from "@mui/material/Box";
import { AmountCommand } from "./AmountCommand";
import { LockAndUnLockCommand } from "./LockAndUnLockCommand";
import { UnLockButton } from "./UnLockButton";
import { UserShow } from "./UserShow";
import { AddFriendsCommand } from "./AddFriendsCommand";
import { MyRefTable } from "../../components/core/MyRefTable";

const fields: MyField[] = [
  Field.id({
    label: "用户ID",
    minWidth: 140,
  }),
  Field.img({
    maxWidth: 80,
    label: "头像",
    source: "faceURL",
    isEdit: false,
    isCreate: false,
    isUpdate: false,
  }),
  Field.text({
    label: "账号",
    source: "name",
    sortable: false,
    isFilter: true,
    isUpdate: false,
  }),
  Field.text({
    label: "用户唯一号",
    source: "viewId",
    sortable: false,
    isFilter: true,
  }),
  Field.text({
    label: "昵称",
    source: "nickname",
    sortable: false,
    isFilter: true,
  }),
  Field.text({
    label: "安全问题答案",
    source: "securityAnswer",
    sortable: false,
    isFilter: true,
  }),
  Field.functionField({
    label: "在线状态",
    source: "onlineStatus",
    isEdit: false,
    isUpdate: false,
    sortable: false,
    isFilter: false,
    isCreate: false,
    render(record: any, source: string | undefined): any {
      if (record.onlineStatus.status === "offline") {
        return "离线";
      } else {
        let status = keyBy(
          record.onlineStatus.detailPlatformStatus,
          "platform"
        );
        const platformIcons: Record<string, any> = {
          ios: (
            <RiAppleFill key={"ios"} size={12} style={{ color: "#007aff" }} />
          ),
          android: (
            <RiAndroidFill
              key={"android"}
              size={12}
              style={{ color: "#007aff" }}
            />
          ),
          web: (
            <RiGlobalLine key={"web"} size={12} style={{ color: "#1da1f2" }} />
          ),
        };

        const platforms = ["web", "android", "ios"];
        return (
          <>
            在线
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#ddd",
                borderRadius: "50%",
                padding: 0.5,
                marginLeft: 1,
                color: "white",
              }}
            >
              {platforms.map((p) => {
                if (status[p]?.status === "online") {
                  return platformIcons[p];
                }
                return undefined;
              })}
            </Box>
          </>
        );
      }
    },
  }),
  Field.functionField({
    label: "状态",
    source: "status",
    isEdit: false,
    isUpdate: false,
    isCreate: false,
    sortable: false,
    isFilter: false,
    minWidth: 110,
    render(record: any, source: string | undefined): any {
      if (record.lock > -6 || record.status === "LOCK") {
        return (
          <>
            <UnLockButton />
          </>
        );
      } else if (record.status === "NORMAL") {
        return (
          <>
            <UnLockButton />
          </>
        );
      } else if (record.status === "DISABLE") {
        return "删除";
      }
    },
  }),

  Field.amount({
    label: "余额",
    source: "wallet.allBalance",
    isEdit: false,
    isCreate: false,
    isUpdate: false,
  }),
  Field.amount({
    label: "锁定余额",
    source: "wallet.frozenBalance",
    isEdit: false,
    isCreate: false,
    isUpdate: false,
  }),
  {
    label: "密码",
    source: "password",
    isList: false,
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: TextInput,
    editProps: {
      validate: [minLength(4), maxLength(25)],
    },
  },
  {
    label: "支付密码",
    source: "payPassword",
    isList: false,
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: TextInput,
    editProps: {
      validate: [minLength(6), maxLength(6), regex(/^\d{6}$/, "请输入6位数字")],
    },
  },
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  // Field.dateTime({
  //   label: "最后登录时间",
  //   source: "lastLoginTime",
  // }),
];

export const UsersTable = () => {
  return <MyRefTable fields={fields} />;
};

const resource: ResourceType = {
  commands: [
    <ShowCommand />,
    <CreateCommand />,
    <EditCommand />,
    <AddFriendsCommand />,
    <AmountCommand />,
    <AmountCommand isPayment={false} />,
    <LockAndUnLockCommand />,
  ],
  show: () => {
    return <UserShow fields={fields} />;
  },
  list: () => {
    return (
      <MyList sort={{ field: "createdDate", order: "DESC" }} fields={fields} />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return (
      <MyEdit
        transform={(data) => {
          if (data?.password?.length === 0) {
            return omit(data, ["password"]);
          }
          if (data?.payPassword?.length === 0) {
            return omit(data, ["payPassword"]);
          }
          return data;
        }}
        fields={fields}
      />
    );
  },
  icon: <VisitorIcon />,
};

export default resource;
