import { DeleteButton, EditButton, ImageField, TextInput } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { Assessment } from "@mui/icons-material";
import {
  SiteResourceType,
  SiteResourceTypeChoices,
} from "../../model/SiteResource";
import { MyRichTextInput } from "../../components/MyRichTextInput";
import { MyImageInput } from "../../components/MyImageInput";
import { MyImageSwipersValueInput } from "../../components/MyImageSwipersValueInput";
import { MyLocalesValueField } from "../../components/MyLocalesValueField";

const fields: MyField[] = [
  Field.text({
    label: "ID",
    source: "id",
    isCreate: true,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.select({
    label: "类型",
    source: "type",
    isFilter: true,
    choices: SiteResourceTypeChoices,
  }),
  Field.text({
    label: "名称&说明",
    source: "title",
    isFilter: false,
    isRequired: true,
  }),
  Field.dependency({
    label: "正文",
    fullWidth: true,
    source: "values",
    render(record: any, source?: string) {
      console.log("render", record, source);
      const type: SiteResourceType = record.type;
      if (!type) {
        return undefined;
      }

      if (type === SiteResourceType.HTML) {
        return <MyLocalesValueField source="value" />;
      } else if (type === SiteResourceType.IMAGE) {
        return (
          <ImageField
            source="image"
            sx={{
              "& img": {
                maxWidth: "50px !important",
                maxHeight: "30px !important",
                margin: "0 !important",
                objectFit: "contain",
              },
              "&": {
                maxWidth: 50,
                maxHeight: 30,
                objectFit: "contain",
                overflow: "hidden",
              },
            }}
          />
        );
      } else if (type === SiteResourceType.IMAGES) {
        return (
          <ImageField
            source="images"
            src={"url"}
            sx={{
              "& .RaImageField-list": {
                p: 0,
                m: 0,
              },
              "& img": {
                maxWidth: "50px !important",
                maxHeight: "30px !important",
                margin: "0 !important",
                objectFit: "contain",
              },
              "&": {
                maxHeight: 30,
                objectFit: "contain",
                overflow: "hidden",
              },
            }}
          />
        );
      }
      return <MyLocalesValueField source="value" />;
    },
    editRender({ formData, rest }) {
      console.log("formData", formData, rest);
      const type: SiteResourceType = formData.type;
      if (!type) {
        return undefined;
      }
      if (type === SiteResourceType.HTML) {
        return <MyRichTextInput label={"内容"} source="value" {...rest} />;
      } else if (type === SiteResourceType.IMAGE) {
        return <MyImageInput label={"内容"} source="image" {...rest} />;
      } else if (type === SiteResourceType.IMAGES) {
        return (
          <MyImageSwipersValueInput label={"内容"} source="images" {...rest} />
        );
      }
      return <TextInput source="values" {...rest} />;
    },
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList fields={fields} rowActions={[<EditButton />, <DeleteButton />]} />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <Assessment />,
};

export default resource;
