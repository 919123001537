import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";

export const FriendApi = {
  importFriend: async (
    ownerUserId: string,
    friendUserIds: string[]
  ): Promise<any> => {
    let urlSearchParams = new URLSearchParams({
      ownerUserId,
    });
    friendUserIds.forEach((friendUserId, index) => {
      urlSearchParams.append("friendUserIds[" + index + "]", friendUserId);
    });

    const { data } = await fetchJson(`${API_URL}/userFriends/importFriend`, {
      method: "POST",
      body: urlSearchParams,
    });
    return data;
  },
};
