import { IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import {
  TextFieldProps,
  useRecordContext,
  useResourceDefinition,
} from "react-admin";
import { Customer } from "../types";
import { ContentCopy } from "@mui/icons-material";
import CopyToClipboard from "./CopyToClipboard";
import { useCreatePath, useResourceContext } from "ra-core";
import { Link } from "react-router-dom";

interface IdFieldProps extends TextFieldProps {
  fullWidth: boolean | undefined;
  fontSize?: number | string;
}

const IdField = (props: IdFieldProps) => {
  const { fontSize = "0.9rem" } = props;
  const resource = useResourceContext();
  const record = useRecordContext<Customer>();
  const resourceDefinition = useResourceDefinition();
  const createPath = useCreatePath();
  const to = createPath({ type: "show", resource, id: record?.id });
  const hasShow = resourceDefinition.hasShow;

  if (!record || !props.source) {
    return null;
  }
  const value = record[props.source];
  if (!value) {
    return null;
  }
  let text = `${value}`;

  return (
    <Tooltip title={`ObjectId: ${value}`}>
      <>
        {hasShow && (
          <Link to={to} style={{ fontSize: fontSize }}>
            {text}
          </Link>
        )}
        {!hasShow && text}
        <CopyToClipboard>
          {({ copy }) => (
            <IconButton onClick={() => copy(value)}>
              <ContentCopy sx={{ fontSize: fontSize }} />
            </IconButton>
          )}
        </CopyToClipboard>
      </>
    </Tooltip>
  );
};

export default IdField;
