import * as React from "react";
import { ReactElement } from "react";
import PropTypes from "prop-types";
import {
  ChoicesContextProvider,
  InputProps,
  ListContextProvider,
  ResourceContextProvider,
  useListController,
  useReferenceArrayInputController,
  UseReferenceArrayInputParams,
} from "ra-core";
import { Box, Card, InputLabel } from "@mui/material";

const defaultSort = { field: "id", order: "DESC" as const };

export const MyReferenceTableInput = (props: MyReferenceTableInputProps) => {
  const {
    children,
    debounce,
    filter = defaultFilter,
    perPage = 1000,
    label,
    reference,
    sort = defaultSort,
  } = props;

  if (React.Children.count(children) !== 1) {
    throw new Error(
      "<MyReferenceTableInput> only accepts a single child (like <Datagrid>)"
    );
  }
  const listContext = useListController({
    debounce,
    filter,
    sort,
    resource: reference,
    perPage,
  });
  const controllerProps = useReferenceArrayInputController({
    ...props,
    sort,
    enableGetChoices: () => false,
    filter,
  });

  return (
    // @ts-ignore
    <ResourceContextProvider value={reference}>
      <ChoicesContextProvider value={controllerProps}>
        <ListContextProvider value={listContext}>
          <Box p={"8px 0"}>
            <InputLabel sx={{ m: "0 0 8px 0" }}>{label}:</InputLabel>
            <Box display={"grid"}>
              <Box>{children}</Box>
            </Box>
          </Box>
        </ListContextProvider>
      </ChoicesContextProvider>
    </ResourceContextProvider>
  );
};

MyReferenceTableInput.propTypes = {
  children: PropTypes.element,
  filter: PropTypes.object,
  label: PropTypes.string,
  page: PropTypes.number,
  perPage: PropTypes.number,
  reference: PropTypes.string.isRequired,
  resource: PropTypes.string,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.oneOf(["ASC", "DESC"]),
  }),
  source: PropTypes.string,
};

const defaultFilter = {};

export interface MyReferenceTableInputProps
  extends InputProps,
    UseReferenceArrayInputParams {
  children?: ReactElement;
  label?: string;

  [key: string]: any;
}
