import { CommandProps } from "../../components/command/Command";
import { BaseCommand } from "../../components/command/BaseCommand";
import { CloudSync } from "@mui/icons-material";
import React from "react";
import { UserApi } from "../../api/UserApi";

export interface LockAndUnLockCommandProps extends Partial<CommandProps> {}

export function LockAndUnLockCommand(props: LockAndUnLockCommandProps) {
  const {
    command,
    enableTool = false,
    enableRow = true,
    permission = "update",
    ...rest
  } = props;
  return (
    <BaseCommand
      command={{
        isEnable: (rowData: any) => {
          return true;
        },
        label: (r) => {
          if (r.status === "LOCK") {
            return "解锁";
          }
          return "锁定";
        },
        confirm: true,
        icon: <CloudSync />,
        onHandle: async (e, r) => {
          if (r.status === "LOCK") {
            await UserApi.unlock(r.id);
          } else {
            await UserApi.lock(r.id);
          }
          return true;
        },
        ...command,
        // to: (record, rd) => `#/${rd?.name}/create`,
      }}
      enableTool={enableTool}
      enableRow={enableRow}
      permission={permission}
      {...rest}
    />
  );
}
