import { DeleteButton, EditButton } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import ArticleIcon from "@mui/icons-material/Article";

const fields: MyField[] = [
  Field.text({
    label: "ID",
    source: "id",
    isCreate: false,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.ref({
    label: "分类",
    source: "docCategoryId",
    isRequired: false,
    isList: true,
    isFilter: false,
    reference: "docCategories",
    referenceLabel: "key",
    originSource: "docCategoryId",
  }),
  Field.localesValue({
    label: "标题",
    source: "title",
    fullWidth: true,
    isFilter: false,
  }),
  Field.richText({
    label: "正文",
    source: "content",
    fullWidth: true,
    isFilter: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList fields={fields} rowActions={[<EditButton />, <DeleteButton />]} />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: <ArticleIcon />,
};

export default resource;
