import React, { createContext, ReactNode, useContext } from "react";
import { CommandProps } from "../components/command/Command";

export interface ResourceConfig {
  hasDelete: boolean;
  resource: string;
  commands?: React.ReactElement<CommandProps>[];
}

// 创建一个默认配置
const defaultConfig: ResourceConfig = {
  hasDelete: false,
  resource: "",
  commands: [],
};

// 创建Context
const ResourceConfigContext = createContext<ResourceConfig>(defaultConfig);

// Context 提供者组件
export const ResourceConfigProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: ResourceConfig;
}) => {
  return (
    <ResourceConfigContext.Provider value={value}>
      {children}
    </ResourceConfigContext.Provider>
  );
};

export const useResourceConfig = (): ResourceConfig => {
  const context = useContext(ResourceConfigContext);

  if (context === undefined) {
    throw new Error(
      "useResourceConfig must be used within a ResourceConfigProvider"
    );
  }

  return context;
};

export const useCommands = () => {
  const context = useContext(ResourceConfigContext);
  if (context === undefined) {
    throw new Error("useCommands must be used within a CommandsProvider");
  }
  return context.commands;
};
