import React, { createContext, ReactNode, useContext } from "react";

export interface CommandItemContextConfig {
  isInRow: boolean;
  isInTool: boolean;
}

// 创建一个默认配置
const defaultConfig: CommandItemContextConfig = {
  isInRow: false,
  isInTool: false,
};

// 创建Context
const CommandItemContext = createContext<CommandItemContextConfig>(
  defaultConfig
);

// Context 提供者组件
export const CommandItemContextProvider = ({
  children,
  isInRow,
  isInTool,
}: {
  children: ReactNode;
  isInRow: boolean;
  isInTool: boolean;
}) => {
  return (
    <CommandItemContext.Provider value={{ isInRow, isInTool }}>
      {children}
    </CommandItemContext.Provider>
  );
};

export const useCommandItemContext = (): CommandItemContextConfig => {
  const context = useContext(CommandItemContext);
  if (context === undefined) {
    throw new Error(
      "useCommandItemContext must be used within a CommandItemContextProvider"
    );
  }

  return context;
};
