import polyglotI18nProvider from "ra-i18n-polyglot";
import { CustomRoutes, memoryStore } from "react-admin";
import { Route } from "react-router";

import Polyglot from "node-polyglot";
import authProvider from "./authProvider";
import { API_URL } from "./config";
import Configuration from "./configuration/Configuration";
import { MyDashboard } from "./dashboard";
import HttpDataProvider from "./dataProvider/HttpDataProvider";
import chineseMessages from "./i18n/ch";
import { Layout, Login } from "./layout";
import { lightTheme } from "./layout/themes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createMenuContext } from "./hook/MenuCentext";
import { menuItems } from "./MenuData";
import { MyResource } from "./components/core/MyResource";
import { ConfirmDialogProvider } from "./hook/ConfirmContext";
import MyAdmin from "./components/core/MyAdmin";
import Admins from "./pages/Admin";
import Users from "./pages/user";
import Groups from "./pages/group";
import PayRecords from "./pages/PayRecord";
import PayProviders from "./pages/PayProvider";
import RobotProviders from "./pages/RobotProvider";
import GroupReds from "./pages/groupRed";
import GroupRedRecords from "./pages/groupRedRecord";
import TagCategorys from "./pages/TagCategory";
import LoginLogs from "./pages/LoginLog";
import SysRole from "./pages/sys/SysRole";
import SysModule from "./pages/sys/SysModule";
import AppVersion from "./pages/app/AppVersion";
import AppsPage from "./pages/app/AppsPage";
import SiteNotification from "./pages/content/SiteNotification";
import SiteResource from "./pages/content/SiteResource";
import Docs from "./pages/content/Doc";
import DocCategories from "./pages/content/DocCategory";
import Settings from "./pages/setting/setting";
import GroupMembers from "./pages/groupMember";
import UserFriends from "./pages/userFriends";
import UserFriendSearch from "./pages/userFriendSearch";

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    return chineseMessages;
  },
  "ch",
  {
    onMissingKey: (
      key: string,
      options: Polyglot.InterpolationOptions,
      locale: string
    ) => key,
  }
);

// function NewComponent() {
//   let notify = useNotify();
//
//   console.log(notify);
//   debugger;
//
//   window.addEventListener("error", (error) => {
//     console.log("捕获到异常：", error.error);
//     notify(error.error);
//   });
//   window.addEventListener("unhandledrejection", (error) => {
//     console.log("捕获到异常：", error.reason.message);
//     notify(error.reason.message);
//   });
//
//   return <div></div>;
// }

const App = () => {
  const { Provider: MenuProvider } = createMenuContext(menuItems);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ConfirmDialogProvider>
        <MenuProvider>
          <MyAdmin
            title="管理后台"
            dataProvider={HttpDataProvider(API_URL)}
            authProvider={authProvider}
            dashboard={MyDashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            store={memoryStore()}
            theme={lightTheme}
          >
            <CustomRoutes>
              <Route path="/configuration" element={<Configuration />} />
            </CustomRoutes>
            <MyResource name="admins" {...Admins} />
            <MyResource name="users" {...Users} />
            <MyResource name="userFriends" {...UserFriends} />
            <MyResource name="userFriendSearch" {...UserFriendSearch} />
            <MyResource name="loginLogs" {...LoginLogs} />
            <MyResource name="payRecords" {...PayRecords} />

            <MyResource name="tagCategorys" {...TagCategorys} />
            <MyResource name="groups" {...Groups} />
            <MyResource name="groupMembers" {...GroupMembers} />
            <MyResource name="sysModules" {...SysModule} />
            <MyResource name="sysRoles" {...SysRole} />

            <MyResource name="docs" {...Docs} />
            <MyResource name="docCategories" {...DocCategories} />
            <MyResource name="settings" {...Settings} />

            <MyResource name="siteResource" {...SiteResource} />
            <MyResource name="siteNotification" {...SiteNotification} />
            <MyResource name="payProviders" {...PayProviders} />
            <MyResource name="robotProviders" {...RobotProviders} />

            <MyResource name="groupReds" {...GroupReds} />
            <MyResource name="groupRedRecords" {...GroupRedRecords} />

            <MyResource name="apps" {...AppsPage} />
            <MyResource name="appVersions" {...AppVersion} />
          </MyAdmin>
        </MenuProvider>
      </ConfirmDialogProvider>
    </LocalizationProvider>
  );
};

export default App;
