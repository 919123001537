import * as React from "react";
import { TextFieldProps, useRecordContext } from "react-admin";
import { Customer } from "../types";
import get from "lodash/get";

interface ObjectIdFieldProps extends TextFieldProps {}

const MyTextField = (props: ObjectIdFieldProps) => {
  const record = useRecordContext<Customer>();
  if (!record || !props.source) {
    return null;
  }
  const value = get(record, props.source, null);
  if (!value) {
    return null;
  }

  const text = "合计" + value.length + "项目";

  return <span>{text}</span>;
};

export default MyTextField;
