import { TranslationMessages } from "react-admin";

const customChineseMessages: TranslationMessages = {
  ra: {
    action: {
      add_filter: "添加筛选器",
      add: "添加",
      back: "返回",
      bulk_actions: "已选择 %{smart_count} 个项目",
      cancel: "取消",
      clear_array_input: "清空列表",
      clear_input_value: "清除值",
      clone: "克隆",
      confirm: "确认",
      create: "创建",
      create_item: "创建 %{item}",
      delete: "删除",
      edit: "编辑",
      export: "导出",
      list: "列表",
      refresh: "刷新",
      remove_filter: "移除此筛选器",
      remove_all_filters: "移除所有筛选器",
      remove: "移除",
      save: "保存",
      search: "搜索",
      select_all: "全选",
      select_row: "选择此行",
      show: "显示",
      sort: "排序",
      undo: "撤销",
      unselect: "取消选择",
      expand: "展开",
      close: "关闭",
      open_menu: "打开菜单",
      close_menu: "关闭菜单",
      update: "更新",
      move_up: "上移",
      move_down: "下移",
      open: "打开",
      toggle_theme: "切换明暗模式",
      select_columns: "列",
      update_application: "重新加载应用",
    },
    boolean: {
      true: "是",
      false: "否",
      null: " ",
    },
    page: {
      create: "创建 %{name}",
      dashboard: "仪表盘",
      edit: "%{name} %{recordRepresentation}",
      error: "发生错误",
      list: "%{name}",
      loading: "加载中",
      not_found: "未找到",
      show: "%{name} %{recordRepresentation}",
      empty: "尚无 %{name}。",
      invite: "要添加一个吗？",
    },
    input: {
      file: {
        upload_several: "拖拽多个文件上传，或点击选择。",
        upload_single: "拖拽文件上传，或点击选择。",
      },
      image: {
        upload_several: "拖拽多张图片上传，或点击选择。",
        upload_single: "拖拽图片上传，或点击选择。",
      },
      references: {
        all_missing: "未能找到参考数据。",
        many_missing: "至少有一个关联的参考已不可用。",
        single_missing: "关联的参考已不可用。",
      },
      password: {
        toggle_visible: "隐藏密码",
        toggle_hidden: "显示密码",
      },
    },
    message: {
      about: "关于",
      are_you_sure: "您确定吗？",
      auth_error: "验证身份令牌时发生错误。",
      bulk_delete_content:
        "您确定要删除 %{name} 吗？ |||| 您确定要删除这 %{smart_count} 项吗？",
      bulk_delete_title: "删除 %{name} |||| 删除 %{smart_count} 个 %{name}",
      bulk_update_content:
        "您确定要更新 %{name} 吗？ |||| 您确定要更新这 %{smart_count} 项吗？",
      bulk_update_title: "更新 %{name} |||| 更新 %{smart_count} 个 %{name}",
      clear_array_input: "您确定要清空整个列表吗？",
      delete_content: "您确定要删除此项目吗？",
      delete_title: "删除 %{name} #%{id}",
      details: "详情",
      error: "发生客户端错误，您的请求无法完成。",
      invalid_form: "表单不合法。请检查错误",
      loading: "请稍候",
      no: "否",
      not_found: "您输入了错误的URL，或点击了错误的链接。",
      yes: "是",
      unsaved_changes: "您有未保存的更改。您确定要忽略它们吗？",
    },
    navigation: {
      clear_filters: "清除筛选器",
      no_filtered_results: "未找到使用当前筛选器的 %{resource}。",
      no_results: "未找到 %{resource}",
      no_more_results: "页码 %{page} 超出范围。请尝试上一页。",
      page_out_of_boundaries: "页码 %{page} 超出范围",
      page_out_from_end: "无法前往最后一页之后",
      page_out_from_begin: "无法前往第一页之前",
      page_range_info: "%{offsetBegin}-%{offsetEnd} 共 %{total}",
      partial_page_range_info: "%{offsetBegin}-%{offsetEnd} 超过 %{offsetEnd}",
      current_page: "第 %{page} 页",
      page: "前往第 %{page} 页",
      first: "前往第一页",
      last: "前往最后一页",
      next: "前往下一页",
      previous: "前往上一页",
      page_rows_per_page: "每页行数：",
      skip_nav: "跳至内容",
    },
    sort: {
      sort_by: "按 %{field} %{order} 排序",
      ASC: "升序",
      DESC: "降序",
    },
    auth: {
      auth_check_error: "请登录以继续",
      user_menu: "个人资料",
      username: "用户名",
      password: "密码",
      sign_in: "登录",
      sign_in_error: "认证失败，请重试",
      logout: "退出登录",
    },
    notification: {
      updated: "元素已更新 |||| %{smart_count} 个元素已更新",
      created: "元素已创建",
      deleted: "元素已删除 |||| %{smart_count} 个元素已删除",
      bad_item: "错误的元素",
      item_doesnt_exist: "元素不存在",
      http_error: "服务器通信错误",
      data_provider_error: "dataProvider 错误。详细信息请查看控制台。",
      i18n_error: "无法加载指定语言的翻译",
      canceled: "操作已取消",
      logged_out: "您的会话已结束，请重新连接。",
      not_authorized: "您无权访问此资源。",
      application_update_available: "有新版本可用。",
    },
    validation: {
      required: "必填",
      minLength: "至少 %{min} 个字符",
      maxLength: "最多 %{max} 个字符",
      minValue: "至少为 %{min}",
      maxValue: "不超过 %{max}",
      number: "必须是数字",
      email: "必须是有效的邮箱",
      oneOf: "必须是以下之一：%{options}",
      regex: "必须符合特定格式（正则表达式）：%{pattern}",
      unique: "必须唯一",
    },
    saved_queries: {
      label: "已保存的查询",
      query_name: "查询名称",
      new_label: "保存当前查询...",
      new_dialog_title: "将当前查询保存为",
      remove_label: "移除已保存的查询",
      remove_label_with_name: '移除查询 "%{name}"',
      remove_dialog_title: "移除已保存的查询？",
      remove_message: "您确定要将此项目从已保存的查询列表中移除吗？",
      help: "过滤列表并保存此查询以备后用",
    },
    configurable: {
      customize: "自定义",
      configureMode: "配置此页面",
      inspector: {
        title: "检查器",
        content: "悬停在应用程序UI元素上以配置它们",
        reset: "重置设置",
        hideAll: "全部隐藏",
        showAll: "全部显示",
      },
      Datagrid: {
        title: "数据网格",
        unlabeled: "未标记的列 #%{column}",
      },
      SimpleForm: {
        title: "表单",
        unlabeled: "未标记的输入 #%{input}",
      },
      SimpleList: {
        title: "列表",
        primaryText: "主要文本",
        secondaryText: "次要文本",
        tertiaryText: "第三文本",
      },
    },
  },
};
export default customChineseMessages;
