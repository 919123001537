import isUndefined from "lodash/isUndefined";
import { MyProDataGrid } from "./MyProDataGrid";
import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { GridActionsCellItemProps } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import {
  GridGroupingColDefOverride,
  GridGroupingColDefOverrideParams,
} from "@mui/x-data-grid-pro/models/gridGroupingColDefOverride";
import { RaRecord, useChoicesContext, useInput } from "ra-core";
import { MyField } from "../types";
import { GridGroupNode } from "@mui/x-data-grid";
import { CommandProps } from "../command/Command";

export interface MyDatagridProps<RecordType extends RaRecord = any> {
  fields: MyField<RecordType>[];
  commands?: React.ReactElement<CommandProps>[];
  renderActionCell?: (
    params: GridRenderCellParams<any, any, any>
  ) => React.ReactNode;
  getTreeDataPath?: (row: RecordType) => string[];
  isGroupExpandedByDefault?: (node: GridGroupNode) => boolean;
  groupingColDef?:
    | GridGroupingColDefOverride<RecordType>
    | ((
        params: GridGroupingColDefOverrideParams
      ) => GridGroupingColDefOverride<RecordType> | undefined | null);
}

export function MyDatagrid<RecordType extends RaRecord = any>(
  props: MyDatagridProps<RecordType>
) {
  const { renderActionCell, fields, commands, ...rest } = props;
  return (
    <MyProDataGrid
      size="small"
      renderActionCell={renderActionCell}
      bulkActionButtons={<></>}
      commands={commands}
      getTreeDataPath={props.getTreeDataPath}
      isGroupExpandedByDefault={props.isGroupExpandedByDefault}
      groupingColDef={props.groupingColDef}
      fields={fields.filter(
        (f) => (f.isList || isUndefined(f.isList)) && f.component
      )}
    ></MyProDataGrid>
  );
}

export interface MyDatagridSelectProps<RecordType extends RaRecord = any> {
  fields: MyField<RecordType>[];
  perPage?: number;
  renderActionCell?: (
    params: GridRenderCellParams<any, any, any>
  ) => React.ReactNode;
  getTreeDataPath?: (row: RecordType) => string[];
  rowActions?: React.ReactElement<GridActionsCellItemProps>[];
  isGroupExpandedByDefault?: (node: GridGroupNode) => boolean;
  groupingColDef?:
    | GridGroupingColDefOverride<RecordType>
    | ((
        params: GridGroupingColDefOverrideParams
      ) => GridGroupingColDefOverride<RecordType> | undefined | null);
  isSelectRow?: (row: RecordType) => boolean;
}

export function MyDatagridSelect<RecordType extends RaRecord = any>(
  props: MyDatagridSelectProps<RecordType>
) {
  const {
    perPage = 25,
    renderActionCell,
    rowActions = [],
    fields,
    isSelectRow,
    ...rest
  } = props;

  const { resource, source } = useChoicesContext({
    perPage: perPage,
  });

  const { field } = useInput({
    resource,
    source,
    ...rest,
  });

  return (
    <MyProDataGrid
      size="small"
      onSelect={(ids) => {
        field.onChange(ids);
      }}
      isSelectRow={isSelectRow}
      selectedIds={field.value}
      renderActionCell={renderActionCell}
      bulkActionButtons={false}
      getTreeDataPath={props.getTreeDataPath}
      isGroupExpandedByDefault={props.isGroupExpandedByDefault}
      groupingColDef={props.groupingColDef}
      fields={fields.filter(
        (f) => (f.isList || isUndefined(f.isList)) && f.component
      )}
    ></MyProDataGrid>
  );
}
