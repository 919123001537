import { EditButton } from "react-admin";
import { MyList } from "../../components/core/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/core/MyCreate";
import MyEdit from "../../components/core/MyEdit";
import { ContactEmergency } from "@mui/icons-material";
import { ResourceType } from "../../components/core/MyResource";
import { DeleteCommand } from "../../components/command/DeleteCommand";
import { MyDatagridSelect } from "../../components/core/MyDatagrid";

const fields: MyField<any>[] = [
  Field.id({
    minWidth: 140,
  }),
  Field.img({
    maxWidth: 80,
    label: "头像",
    source: "faceURL",
    isEdit: false,
    isCreate: false,
    isUpdate: false,
  }),
  Field.text({
    label: "账号",
    source: "name",
    sortable: false,
    isFilter: true,
    isUpdate: false,
  }),
  Field.boolean({
    label: "是否好友",
    source: "isMyFriendship",
    sortable: false,
    isFilter: true,
    isUpdate: false,
  }),
  Field.text({
    label: "用户唯一号",
    source: "viewId",
    sortable: true,
    isFilter: false,
  }),
  Field.text({
    label: "昵称",
    source: "nickname",
    sortable: true,
    isFilter: false,
  }),
  Field.text({
    label: "备注",
    source: "remark",
    sortable: false,
    isFilter: false,
  }),
];

export const UserFriendSearchTable = () => {
  return (
    <MyDatagridSelect
      fields={fields}
      isSelectRow={(data) => data.isMyFriendship}
    />
  );
};

const resource: ResourceType = {
  commands: [],
  icon: <ContactEmergency />,
};

export default resource;
