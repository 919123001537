import { MyField } from "../../components/types";
import { base, BaseArgs } from "./base";
import { MyAutocompleteArrayInput } from "../../components/MyAutocompleteArrayInput";
import MyArrayField from "../../components/MyArrayField";

export interface AutocompleteArrayArgs extends BaseArgs {
  choices?: any[] | undefined;
}

const defaultArgs: Partial<AutocompleteArrayArgs> = {
  isRequired: false,
  fullWidth: false,
  isFilter: false,
  sortable: true,
};

export function autocompleteArray(
  args: AutocompleteArrayArgs,
  field?: MyField
) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: MyArrayField,
      editComponent: MyAutocompleteArrayInput,
    },
    field
  );
}
